import React, {useEffect} from 'react';
import {Button, Collapse, Divider, Form, Row, Spin} from "antd";
import useActions from "../../../../../hooks/useActions";
import {getActionTypes} from "../../../../../helpers/getActionTypes";
import {useSelector} from "react-redux";
import "./AvailabilityRulesForm.scss";
import AvailabilityRuleForm from "./AvailabilityRuleForm/AvailabilityRuleForm";
import {DeleteOutlined} from "@ant-design/icons";
import {SET_SHIPPING_METHOD} from "../../../../../redux-store/reducers/shopReducers/shippingMethodsReducer";

const AvailabilityRulesForm = ({shippingMethod}) => {
    const {getItems, setItemNull} = useActions();
    const {id, rules, site_id} = shippingMethod;
    const {shippingClasses, loading} = useSelector(state => state?.shippingClasses);

    useEffect(() => {
        getItems({
            params: {site_id},
            ...getActionTypes('shipping_classes')
        })

        return () => {
            setItemNull(SET_SHIPPING_METHOD)
        }
    }, [])

    if (loading) {
        return <Row justify='center' align='middle' style={{height: '100%', width: '100%'}}><Spin size='medium'/></Row>
    }

    return (
        <div style={{marginBottom: '20px'}}>
            <h2>Tillgänglighetsregeler</h2>
            <Collapse className="site-collapse-custom-collapse">
                {
                    shippingClasses.objects?.length > 0 &&
                    shippingClasses.objects?.map((data, index) => {
                        const shippingRules = rules.filter(rule => +rule.shipping_class_id === +data.id);
                        return (
                            shippingRules &&
                            <Collapse.Panel key={data.id} header={data.name} forceRender={true}
                                            className="site-collapse-custom-panel">
                                <Form.List key={data.id} name={'rules_' + index}
                                           initialValue={shippingRules}>
                                    {(fields, {add, remove}) => {
                                        return <div>
                                            {fields.map((field, fieldIndex) => (
                                                <React.Fragment key={field.key}>
                                                    <Row justify='space-between' style={{marginBottom: '20px'}}>
                                                        <AvailabilityRuleForm index={index}
                                                                              fieldIndex={fieldIndex}
                                                                              shippingClassId={data.id}
                                                                              shippingMethodId={id}
                                                                              data={shippingRules}/>
                                                        <Row justify='end' style={{padding: '8px 10px 0 0'}}>
                                                            <DeleteOutlined style={{width: '20px'}}
                                                                            onClick={() => remove(field.name)}/>
                                                        </Row>
                                                    </Row>
                                                    <Divider/>
                                                </React.Fragment>
                                            ))
                                            }
                                            <Row style={{width: '100%'}}>
                                                <Button type='primary' onClick={() => add()}>Lägg till
                                                    regel</Button>
                                            </Row>
                                        </div>
                                    }}
                                </Form.List>
                            </Collapse.Panel>
                        )
                    })
                }
            </Collapse>
        </div>
    );
};

export default AvailabilityRulesForm;
